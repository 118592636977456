// window.addEventListener('load', function () {
//     // console.log('start');
//     let options = {
//         root: document,
//         rootMargin: '0px',
//         threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
//     }


//     let observeHandler = function (ob) {
//         console.log(ob);
//         ob.forEach((el) => {
//             el.target.style.opacity = ob[0].intersectionRatio;
//         });
//     }


//     let observer = new IntersectionObserver(observeHandler, options);

//     let els = document.querySelectorAll('[data-observe]');

//     els.forEach((el) => {
//         observer.observe(el);
//     });
// })


window.addEventListener('load', function () {

    // main menu // hamburger 
    // let main_navigation = document.querySelector('#primary-menu');
    let main_navigation_mobile = document.querySelector('#primary-menu-mobile');
    // let lines = document.querySelectorAll('#primary-menu-toggle span')

    let toggleMobileNav = function(){
        document.querySelector('#primary-menu-toggle').classList.toggle('open');
        main_navigation_mobile.classList.toggle('hidden');
        document.querySelector('body').classList.toggle('fixed');
    }

    document.querySelector('#primary-menu-toggle').addEventListener('click', function (e) {
        e.preventDefault();
        toggleMobileNav();
        // document.querySelector('#primary-menu-toggle').classList.toggle('open');
        // main_navigation_mobile.classList.toggle('hidden');
        // document.querySelector('body').classList.toggle('fixed');
    });



    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        console.log(anchor);

        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            toggleMobileNav();
            console.log(e, this.getAttribute('href'));
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });



});